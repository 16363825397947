<template>
  <div>
    <v-row align="center">
      <v-col cols="10" sm="6" md="4">
        <v-text-field
          outlined
          dense
          v-model="filters.search"
          prepend-inner-icon="mdi-magnify"
          class="ml-auto filters search"
          :label="$t('search')"
          hide-details
          single-line
          @input="searchFilter"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2" md="3" class="pt-3" align="right">
        <SupplierNew @save="fetchSuppliers()"></SupplierNew>
      </v-col>
    </v-row>

    <base-data-table-cards
      :headers="headers"
      :headers_small="headers_small"
      :data="items"
      :total-items="totalSuppliers"
    >
      <template v-slot:[`item_phone`]="item">
        {{ item.phone || "-" }} <WhatsApp :phone_number="item.phone" />
      </template>

      <template v-slot:[`item_email`]="item">
        {{ item.email || "-" }}
      </template>

      <template v-slot:[`item_city`]="item">
        {{ item.city || "-" }}
      </template>
      <template v-slot:[`item_email_phone`]="item">
        <div>
          {{ item.email || "-" }}
        </div>
        <div>
          {{ item.phone || "-" }}
        </div>
      </template>
      <template v-slot:[`item_whatsapp`]="item">
        <WhatsApp :phone_number="item.phone" large classes="mr-2" />
      </template>
      <template v-slot:[`item_actions`]="item">
        <SupplierEdit
          :id="item.id"
          :view="1"
          :ref="`edit-${item.id}`"
          @updated="
            fetchSuppliers();
            $emit('refresh');
          "
        ></SupplierEdit>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" large>mdi-dots-horizontal</v-icon>
          </template>
          <v-list>
            <v-list-item
              class="braList"
              @click="$refs[`edit-${item.id}`].open()"
            >
              <v-list-item-icon>
                <v-icon small>$editprofile</v-icon></v-list-item-icon
              >
              <v-list-item-title>
                <span>{{ $t("edit", { name: "" }) }}</span></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </base-data-table-cards>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { debounce } from "lodash";

export default {
  name: "SupplierList",

  props: ["title"],
  components: {
    SupplierNew: () => import("@/views/supplier/SupplierNew"),
    SupplierEdit: () => import("@/views/supplier/SupplierEdit"),
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("inventorys.name_supplier"),
          align: "center",
          sortable: false,
          value: "supplier_name",
        },

        {
          text: this.$t("email"),
          align: "center",
          sortable: false,
          value: "email",
        },

        {
          text: this.$t("phone"),
          align: "center",
          sortable: false,
          value: "phone",
        },

        {
          text: this.$t("inventorys.city"),
          align: "center",
          sortable: false,
          value: "city",
        },

        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      headers_small: [
        {
          text: this.$t("inventorys.name_supplier"),
          align: "center",
          sortable: false,
          value: "supplier_name",
        },

        {
          text: this.$t("email"),
          align: "center",
          sortable: false,
          value: "email_phone",
        },

        {
          text: this.$t("email"),
          align: "center",
          sortable: false,
          value: "whatsapp",
          no_col: true,
        },
      ],
      supplier: {},
      items: null,
      dialog: false,
      filters: {
        search: "",
        dates: "",
      },
      sortBy: "Nombre",
      rowsPerPageItems: [10, 25, 50, 100, 200],
      options: {},
      loading: true,
      totalSuppliers: 0,
    };
  },
  mounted() {
    this.fetchSuppliers();
  },
  methods: {
    ...mapActions("supplier", ["getSuppliers"]),

    fetchSuppliers() {
      this.loading = true;
      this.getSuppliers({
        pagination: this.options,
        filters: this.filters,
      }).then((data) => {
        //console.log(data);
        this.items = data.data;
        this.totalSuppliers = data.total;
        this.loading = false;
      });
    },
    searchFilter: debounce(function (val) {
      this.fetchSuppliers();
    }, 1000),
  },
};
</script>
<style lang="sass">
.filters .svg-icon, .filters .v-icon
  fill: var(--v-primary-base)
  color: var(--v-primary-base)
  height: 20px

.filters .v-label
  color: white
.v-application .col-6
  //padding-right: 12px !important

.braList
  height: 30px
  .v-list-item__icon
    margin-right: 5px !important
#newPopup

.braList .v-icon, .braList .svg-icon
  margin-top: -3px
  color: var(--v-gris1-lighten2)

  @media (min-width: 800px)
    .shorter
      max-width: 170px
</style>
